import { Col, Row, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { AppThunk } from 'App/globalState/store';
import { IPagination } from 'App/types/pagination/pagination';
import React from 'react';
import { useDispatch } from 'react-redux';
import './styles/ExtendedTable.less';

interface ExtendedTableProps {
	requestPagination: IPagination;
	searchable?: boolean;
	fetchDataAction: (params: IPagination) => AppThunk;
}

const ExtendedTable = <RecordType extends object = any>(props: TableProps<RecordType> & ExtendedTableProps) => {
	const { pageNumber, filters, orderBy, pageSize, query, totalItems } = props.requestPagination;
	const { fetchDataAction, searchable, children } = props;
	const { calculatePageNumber, handleOrderByChange, handleFiltersChange, defaultPaginationValues } = paginationUtils;
	const dispatch = useDispatch();

	const paginationConfig: TablePaginationConfig = {
		current: pageNumber,
		pageSize: pageSize,
		total: totalItems,
		showSizeChanger: true,
	};

	const handleSearch = (value: string) => {
		dispatch(
			fetchDataAction({
				...props.requestPagination,
				...defaultPaginationValues,
				query: value,
				orderBy: orderBy,
				filters: filters,
				pageSize: pageSize,
			})
		);
	};

	const handleTableChange = (pagination, filters, sorter, extra): any => {
		let pageNumberToSet = calculatePageNumber(pagination, extra);

		const newOrderBy = handleOrderByChange(sorter);
		const newFilters = handleFiltersChange(filters);

		dispatch(
			fetchDataAction({
				...props.requestPagination,
				...defaultPaginationValues,
				pageNumber: pageNumberToSet,
				pageSize: pagination.pageSize || defaultPaginationValues.pageSize,
				query: query,
				orderBy: newOrderBy,
				filters: newFilters,
			})
		);
	};

	return (
		<Row>
			{searchable && (
				<Col span={24} className='mb-3'>
					<Search allowClear onSearch={handleSearch} />
				</Col>
			)}
			<Col span={24}>
				<Table
					className='extended-table'
					tableLayout='auto'
					rowKey='id'
					scroll={{ x: 'fit-content' }}
					pagination={paginationConfig}
					onChange={handleTableChange}
					{...props}
				>
					{children}
				</Table>
			</Col>
		</Row>
	);
};

export default ExtendedTable;
