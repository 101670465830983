import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, notification, PageHeader, UploadProps } from 'antd';
import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { createEvent } from 'App/globalState/events/events.global.thunk';
import { cleanUpEventsStatus } from 'App/globalState/events/events.global.slice';
import { CreateEventRequest } from 'App/api/endpoints/events/requests';
import SubmitEventForm from './forms/SubmitEventForm';
import { UploadFile } from 'antd/lib/upload/interface';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from "react-helmet";

const { LOADING } = StatusType;

export const SubmitEventContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { t } = useTranslation(['page', 'common']);
	const { executeRecaptcha } = useGoogleReCaptcha();

	let eventsStatus = useSelector((state: RootState) => state.global.events.status);

    const [bannerFile, setBannerFile] = useState<UploadFile>(null);
    const [posterFile, setPosterFile] = useState<UploadFile>(null);

    const isFileImage = (file: UploadFile): boolean => {
        const isImage = file.type === 'image/png' || file.type === 'image/jpeg';
        
        if (!isImage) {
            notification.error({
				message: t('common:Errors.Error'),
				description: t('AdminPages.EventsPages.FileIsNotImage'),
			});
        }

        return isImage;
    }

    const uploadBannerProps: UploadProps = {
        name: 'banner',
        multiple: false,
        maxCount: 1,
        showUploadList: bannerFile != null,
        accept: "image/png, image/jpeg",
		fileList: bannerFile ? [ bannerFile ] : [],
        onRemove: (file) => {
            setBannerFile(null);
		},
		beforeUpload: (file : UploadFile) => {
			const fileSizeInMb = file.size / 1024 / 1024;
			if(fileSizeInMb >= 1){
				notification.error({
					message: t('common:Errors.Error'),
					description: t('AdminPages.EventsPages.FileIsTooLarge'),
				});
				return false;
			}

            if(isFileImage(file))
                setBannerFile(file);
            else
                setBannerFile(null);

			return false;
		}
    };

    const uploadPosterProps: UploadProps = {
        name: 'poster',
        multiple: false,
        maxCount: 1,
        showUploadList: posterFile != null,
        accept: "image/png, image/jpeg",
		fileList: posterFile ? [ posterFile ] : [],
        onRemove: (file) => {
            setPosterFile(null);
		},
		beforeUpload: (file : UploadFile) => {
			const fileSizeInMb = file.size / 1024 / 1024;
			if(fileSizeInMb >= 1){
				notification.error({
					message: t('common:Errors.Error'),
					description: t('AdminPages.EventsPages.FileIsTooLarge'),
				});
				return false;
			}

            if(isFileImage(file))
                setPosterFile(file);
            else
                setPosterFile(null);

			return false;
		}
    };
      
	const handleFormSubmit = async (values: CreateEventRequest) => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available');
			return;
		}
		
        values.banner = bannerFile;
        values.poster = posterFile;

		if(!values.surfaces)
			values.surfaces = null;

		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.EventsPages.SuccessCreateDescription'),
			});

            history.push('/submitted');
		};

		values.token = await executeRecaptcha('eventSubmit');
		dispatch(createEvent(values, onSuccess));
	};

	useEffect(() => {
		return () => {
			dispatch(cleanUpEventsStatus());
		};
	}, [dispatch]);

	return (
		<React.Fragment>
			<Helmet>
                <title id='submit-event'>{t('Navbar.AddEvent')} - Wydarzenia Motoryzacyjne</title>

				{/* META Props */}
				<meta id='submit-event' name="description" content="Dodaj wydarzenie" />

				{/* OG Props */}
				<meta id='submit-event' property="og:description" content="Dodaj wydarzenie" />
				<meta id='submit-event' property="og:url" content="https://kalendarz-motoryzacyjny.pl/dodaj-wydarzenie" />
			</Helmet>
			<Row justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title={t('AdminPages.EventsPages.CreatePageHeaderTitle')} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col xs={24} md={20} lg={15} xl={10} xxl={8}>
							<SubmitEventForm
                                loading={eventsStatus.createEvent === LOADING}
                                onFinish={handleFormSubmit}
                                bannerUploadProps={uploadBannerProps}
                                posterUploadProps={uploadPosterProps}
                            />
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};